const Wedding_Bless = [
  { name: "他是词，你是谱，你俩就是一首和谐的歌。" },
  { name: "天作之合，鸾凤和鸣。千禧年结千年缘，百年身伴百年眠。" },
  { name: "天生才子佳人配，只羡鸳鸯不羡仙。" },
  { name: "各交出一只翅膀，天使新燕，以后共同飞翔在蓝天。" },
  { name: "各交出一份真情，神仙伴侣，以后共同恩爱在人间。" },
  { name: "我交出一毛钱，祝福你们新婚快乐、百年好合！" },
  { name: "珠联壁合洞房春暖，花好月圆鱼水情深。" },
  {
    name: "今天是你们喜结良缘的日子，我代表我家人祝贺你们，祝你俩幸福美满，永寿偕老！",
  },
  {
    name: "花好月圆喜气生，莺歌燕舞传美名，百年佳偶颂同心，喜结连理鸾凤鸣，勤俭持家五世昌，心心相印不了情。祝永结同心！",
  },
];
const TeamBuilding_Bless = [
  {
    name: "团结诚信，拼搏共赢，承载信任，托起希望，挥洒激情，放飞梦想，我们同行，未来精彩。",
  },
  { name: "凝聚产生力量，团结诞生希望。" },
  { name: "同一个团队，同一个梦想，共担责任，共同分享。" },
  { name: "美好未年，共同携手，创造辉煌，谱写新篇。" },
  { name: "凝聚团队力量，激发无限可能。" },
];

export const defaultAvatar = require("@/assets/logo.png");

export const WS_Status = {
  unconnected:0,
  connected: 1,
  connect_close:-1,
  connected_err:-2
}

export const getDefaultBless = (type) => {
  switch (type) {
    case "0":
      return Wedding_Bless;
    case "1":
      return TeamBuilding_Bless;
    default:
      return Wedding_Bless;
  }
};
