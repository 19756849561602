<template>
  <div :class="showSign ? 'signContainer' : 'signContainer-s'">
    <van-action-sheet
      v-model="showWish"
      :actions="wishActions"
      @select="onSelectWish"
    />
    <div v-show="showSign" class="signContainer">
      <div class="signWrap">
        <div class="headPicWrap">
          <img :src="userInfo.avatar" />
        </div>
        <div class="signFormWrap">
          <!--          <div class="signItem" @click="goShowRelation">-->
          <!--            <span>{{ relationShip }}</span>-->
          <!--            <img src="@assets/images/right.png" />-->
          <!--          </div>-->
          <div class="signItem" @click="goShowWish">
            <span>{{ wish }}</span>
            <img src="@assets/images/right.png" />
          </div>
          <div>
            <van-button
              size="large"
              style="width: 120px"
              round
              block
              type="primary"
              @click="sign"
              native-type="sign"
            >
              签到
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { getDefaultBless } from "@utils/constant";
import store from "@/store";
export default {
  name: "Sign",
  data: function () {
    return {
      wish:'',
      relationShip:'',
      userInfo: {},
      wishActions: [],
      showWish: false,
      showSign: false,
    };
  },
  computed: mapGetters(["live"]),
  created() {
    store.dispatch("USERINFO").then((res) => {
      // 获取用户信息
      console.log("获取用户信息++++++++" + JSON.stringify(res));
      this.userInfo = res;
      this.getSign();
      this.getSignList();
    });
  },
  methods: {
    onSelectWish(item){
      this.showWish = false;
      console.log("选中:", item.name);
      this.wish = item.name;
    },
    sign() {
      let param = {
        liveId: this.live.id,
        wish: this.wish,
        relationShip: this.relationShip,
      };
      this.$http.post("/dlr-wapp/live/sign", param).then(() => {
        this.showSign = false;
        this.$toast.success("签到成功!");
        this.$Bus.$emit("refreshCoin");
      });
    },
    goShowWish() {
      this.showWish = true;
    },
    getSign() {
      this.$http
        .get("/dlr-wapp/live/checkSign", { params: { id: this.live.id } })
        .then((res) => {
          this.showSign = !res.data;
        });
    },
    getSignList() {
      this.$http
        .get("/dlr-wapp/liveBlessWord/getList", {
          params: { liveId: this.live.id },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.wishActions = res.data;
          } else {
            this.wishActions = getDefaultBless(this.live.liveType);
          }
          this.wish = this.wishActions[0].name;
        });
    },
  },
};
</script>

<style scoped>
.signContainer {
  position: fixed;
  /* top: 0; */
  /* width: 550px; */
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* z-index: 100000; */
  background-color: rgba(0, 0, 0, 0.6);
}

.signContainer-s {
  position: fixed;
  /* top: 0; */
  /* width: 550px; */
  width: 100vw;
  /*height: 100vh;*/
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /*z-index: 1;*/
  /* z-index: 100000; */
  /*background-color: rgba(0, 0, 0, 0.6);*/
}

.signContainer .signWrap {
  width: 550px;
  background-color: #fff;
  border-radius: 16px;
  /* padding: 20px 0px; */
  position: relative;
  display: flex;
  justify-content: center;
  height: 400px;
}

.signContainer .signWrap .headPicWrap {
  position: absolute;
  /* background-color:#eb8f00!important;  */
  /* opacity:1; */
  top: -85px;
  height: 177px;
  /* background-color: #ff8a00; */
  /* z-index: 10000; */
  /* margin-bottom: 30px; */
}

.signContainer .signWrap .headPicWrap img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.signContainer .signWrap .signFormWrap {
  /* background-color: #FF2; */
  width: 100%;
  border-radius: 16px;
  /* padding: 0 10px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 68px;
}

.signContainer .signWrap .signFormWrap .signItem {
  width: 490px;
  height: 88px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #cccccc;
  font-size: 28px;
  border-radius: 11px;
  padding: 20px;
}

.signContainer .signWrap .signFormWrap .signItem img {
  width: 30px;
  height: 30px;
}
</style>
