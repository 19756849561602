import $store from "../store";
import {store} from "core-js/internals/reflect-metadata";
import {WS_Status, wsStatus} from "@utils/constant";

let websock = null;
let rec; //断线重连后，延迟5秒重新创建WebSocket连接  rec用来存储延迟请求的代码
let isConnect = false; //连接标识 避免重复连接
let closeManual = false;

let globalCallback = function () {};

let createWebSocket = () => {
  try {
    const token = $store.state.app.token;
    let wsUrl = process.env.VUE_APP_WS_URL + "/chatImServer?client=h5";
    console.log("建立连接：" + wsUrl + "," + token);
    websock = new WebSocket(wsUrl, token);
    initWebSocket(); //初始化websocket连接
  } catch (e) {
    console.log("尝试创建连接失败");
    reConnect(); //如果无法连接上webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
  }
};

//定义重连函数
function reConnect(){
  $store.commit('upWsStatus',WS_Status.unconnected);
  if (isConnect) return; //如果已经连上就不在重连了
  console.log("重新连接websocket!");
  rec && clearTimeout(rec);
  rec = setTimeout(function () {
    // 延迟5秒重连  避免过多次过频繁请求重连
    createWebSocket();
  }, 3000);
};
//设置关闭连接
let closeWebSocket = () => {
  closeManual = true;
  websock.close();
};
//心跳设置
const heartCheck = {
  timeout: 13000, //每段时间发送一次心跳包 这里设置为13s
  timeoutObj: null, //延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）
  start: function () {
    this.timeoutObj = setInterval(function () {
      // debugger
      const token = $store.state.app.token;
      let checkMsg = JSON.stringify({
        // 与后端约定，传点消息，保持链接
        msgCode: "heart",
        liveId: $store.state.live.id,
        token: token,
      }); //心跳发送/返回的信息 服务器和客户端收到的信息内容如果如下 就识别为心跳信息 不要做业务处理
      if (isConnect) websock.send(checkMsg);
    }, this.timeout);
  },

  reset: function () {
    clearInterval(this.timeoutObj);
    this.start();
  },
};

// 初始化websocket
function initWebSocket() {
  const token = $store.state.app.token;
  let wsUrl = process.env.VUE_APP_WS_URL + "/chatImServer?client=h5";
  console.log("发起连接：" + wsUrl + "," + token);
  websock = new WebSocket(wsUrl, token);
  websock.onmessage = function (e) {
    websocketonmessage(e);
  };
  websock.onclose = function (e) {
    websocketclose(e);
    $store.commit('upWsStatus',WS_Status.connect_close);
  };
  websock.onopen = function () {
    $store.commit('upWsStatus',WS_Status.connected);
    websocketOpen();
    heartCheck.start();
  };

  // 连接发生错误的回调方法
  websock.onerror = function () {
    console.log("WebSocket连接发生错误");
    isConnect = false; //连接断开修改标识
    $store.commit('upWsStatus',WS_Status.connected_err);
    reConnect(); //连接错误 需要重连
  };
}

// 实际调用的方法
function sendSock(agentData, callback) {
  globalCallback = callback;
  // console.log(globalCallback)
  if (websock.readyState === websock.OPEN) {
    // 若是ws开启状态
    websocketsend(agentData);
  } else if (websock.readyState === websock.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback);
    }, 1000);
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback);
    }, 1000);
  }
}

function getSock(callback) {
  globalCallback = callback;
}
// 数据接收
function websocketonmessage(e) {
  // let O_o = JSON.parse(decodeUnicode(e.data));
  let O_o = JSON.parse(e.data);
  if(O_o.liveId == $store.state.live.id ){
    console.log(e.data);
  }
  if (!O_o) {
    heartCheck.reset();
  } else {
    globalCallback(O_o);
  }
}

// 数据发送
function websocketsend(agentData) {
  console.log(JSON.stringify(agentData));
  websock.send(JSON.stringify(agentData));
}

// 关闭
function websocketclose(e) {
  console.log(e);
  isConnect = false; //断开后修改标识
  console.log("connection closed (" + e.code + ")");
  if(!closeManual){
    console.error('异常关闭ws')
    reConnect(); // 非手动关闭，重新连接
  }
}

// 创建 websocket 连接
function websocketOpen() {
  isConnect = true;
  console.log("ws连接成功");
}

// initWebSocket()

// 将方法暴露出去
export { sendSock, getSock, createWebSocket, closeWebSocket };
