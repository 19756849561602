<template>
  <div class="infoWrap">
    <img :src="wsPic" alt/>
    <div>{{wsTxt}}</div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {WS_Status, wsStatus} from "@utils/constant";
export default {
  name: "WebS",
  data(){
    return{
      wsTxt:'等待连接',
      wsPic: require('@assets/icons/unconnected.png')
    }
  },
  computed:{
    ...mapState({
      wsStatus: (state) => state.app.wsStatus,
    }),
  },
  watch:{
    wsStatus(newVal){
      switch (newVal){
        case WS_Status.unconnected:
            this.wsTxt = '等待连接';
            this.wsPic = require('@assets/icons/unconnected.png');
            break;
        case WS_Status.connected:
          this.wsTxt = '已连接';
          this.wsPic = require('@assets/icons/connected.png');
          break;
        case WS_Status.connect_close:
          this.wsTxt = '连接关闭';
          this.wsPic = require('@assets/icons/unconnected.png');
          break;
        case WS_Status.connected_err:
          this.wsTxt = '连接异常';
          this.wsPic = require('@assets/icons/connect_err.png');
          break;
        default:
          this.wsTxt = '等待连接';
          this.wsPic = require('@assets/icons/unconnected.png');
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.infoWrap {
  position: fixed;
  top: 74px;
  left: 28px;
  /*width: 310px;*/
  //height: 75px;
  background: rgba(0, 0, 0, 0.39);
  /*opacity: 0.4;*/
  //border-radius: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 26px;
  color: #ffffff;
  padding: 5px;
  box-sizing: border-box;
  img{
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
  }
}
</style>
