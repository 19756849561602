<template>
  <div v-show="showRate" class="rateWrap">
    <div>
      当前中奖概率为<span style="color: #dd3333">{{ ratio }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinnerNotice",
  data() {
    return {
      showRate:false,
      ratio: "",
    };
  },
  mounted() {
    this.$Bus.$on("readyLottery", (msg) => {
      let jsonData = JSON.parse(msg);
      this.queryRatio(jsonData.id);
    });
    this.$Bus.$on("closeLottery", (msg) => {
      this.showRate = false;
      this.ratio = "";
    })
  },
  methods:{
    queryRatio(drawId) {
      this.$http
        .get("/dlr-wapp/api/lottery/getRatio", { params: { drawId } })
        .then((res) => {
          this.showRate = true;
          this.ratio = res.data;
        });
    },
  }
};
</script>

<style lang="less" scoped>
.rateWrap {
  color: #333;
  font-size: 24px;
  font-weight: 500;
  text-align: right;
  background-color: #f4b409;
  padding: 10px 12px;
  border-radius: 0 20px 20px 0;
  position: fixed;
  top: 80px;
  left: 0px;
}
</style>
