<template>
  <div class="enterGameWrap">
    <div v-show="live.isGamePlaying" @click="joinGame" class="enterBt">
      进入游戏
    </div>
    <div
      style="bottom: 10px"
      v-show="live.isLotteryPlaying"
      @click="joinLottery"
      class="enterBt"
    >
      加入抽奖
    </div>
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import {mapGetters, mapState} from "vuex";
export default {
  name: "EnterLive",
  computed:{
    ...mapGetters(["live", "lottery"]),
    ...mapState({
      drawingId: (state) => state.live.drawingId,
    }),
  },
  methods: {
    joinLottery() {
      Dialog.confirm({
        title: "提醒：大屏抽奖每人最多中一次奖!",
        message:
          "参与本次抽奖，中奖用户最多消耗金币" +
          this.lottery.maxDeductGoldCoin +
          "个",
      })
        .then(() => {
          const data = this.lottery;
          this.$store.dispatch("live/joinLottery", data).then(() => {
            this.$toast.success("加入成功，等待抽奖!");
          });
        })
        .catch(() => {
          this.$toast.fail({message:"取消加入！"});
        });
    },
    joinGame() {
      let param = {
        id: this.live.playingGameId,
      };
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      this.$http
        .get("/dlr-wapp/liveGame/getDoingLiveGame", { params: param })
        .then((res) => {
          this.$store
            .dispatch("liveGame/joinGame", res.data.id)
            .then((resp) => {
              if (resp.gameUrl) {
                if (resp.gameUrl.startsWith("https://")) {
                  this.$router.push({
                    path: "/iframe",
                  });
                } else {
                  this.$router.push({
                    path: resp.gameUrl,
                  });
                }
              } else {
                this.$toast( "游戏不存在！");
              }
            });
        })
        .catch((err) => {
          // this.$dialog.error(err);
          this.$toast.fail({message:"游戏未开始!"});
        })
        .finally(() => {
          toast.clear();
        });
    },
  },
  watch:{
    drawingId:{
      handler(newVal){
        if(newVal){
          this.$store.dispatch('lottery/initDraw',newVal);
        }
      },
      deep:true
    }
  },
  mounted() {
    this.$Bus.$on("readyGame", (msg) => {
      this.$store.commit("live/showGameReady", msg);
    });
    this.$Bus.$on("readyLottery", (msg) => {
      let jsonData = JSON.parse(msg);
      this.$store.commit("lottery/setLottery", jsonData);
      this.$store.commit("live/showLotteryReady", jsonData);
    });
    this.$Bus.$on("goHome", () => {
      this.$store.commit("live/closeGameReady");
      this.$store.commit("live/closeLottery");
    });
  },
};
</script>
<style lang="less" scoped>
.enterGameWrap {
  width: 100vw;
  /*background-color: #00aaea;*/
  position: fixed;
  bottom: 300px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  .enterBt {
    width: 468px;
    height: 79px;
    line-height: 79px;
    background: #28c76f;
    opacity: 1;
    border-radius: 45px;
    font-size: 34px;
    font-family: PingFang SC;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 20px;
  }
}
</style>
