import dialog from "@utils/dialog";
import axios from "axios";
import {WS_Status, wsStatus} from "@utils/constant";

const state = {
  token: null,
  userInfo: null,
  expires_time: null,
  color:'',
  wsStatus:WS_Status.unconnected,
};

const mutations = {
  upWsStatus(state,status){
    state.wsStatus = status
  },
  SHOW_FOOTER(state) {
    state.footer = true;
  },
  HIDE_FOOTER(state) {
    state.footer = false;
  },
  SHOW_HOME(state) {
    state.home = true;
  },
  HIDE_HOME(state) {
    state.home = false;
  },
  LOGIN(state, token, expires_time) {
    state.token = token;
    state.expires_time = expires_time;
  },
  LOGOUT(state) {
    state.token = null;
    state.expires_time = null;
  },
  BACKGROUND_COLOR(state, color) {
    state.color = color;
    document.body.style.backgroundColor = color;
  },
  UPDATE_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};

const actions = {
  USERINFO({ commit }) {
    return new Promise((resolve) => {
      axios.get("/dlr-wapp/userinfo", {}).then((res) => {
        commit("UPDATE_USERINFO", res.data);
        resolve(res.data);
      });
    }).catch(() => {
      dialog.error("获取信息失败!");
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
